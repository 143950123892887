import { ProductOption } from "@medusajs/medusa";
import { RadioGroup } from "@headlessui/react";
import { classNames, Product, ProductVariant } from "@models/models";
import { VariantInfo } from "./variant-info";
import StockIndicator from "./stock-indicator";
import React from "react";
import VariantPriceDisplay from "@components/variant-price-display";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
  selectBrandThemeText,
} from "@store/site-slice";

type ProductVariantOptionsProps = {
  product: Product;
  setVariantId: (variantId: string) => void;
  onPayWhatYouCanPriceChange?: (price: number) => void;
};

export default function ProductVariantOptions({
  product,
  setVariantId,
  onPayWhatYouCanPriceChange,
}: ProductVariantOptionsProps) {
  const price_box_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.price_box_background_color,
    ),
  );
  const product_detail_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.product_detail_text_color),
  );

  const price_box_selected_border_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.price_box_selected_border_color,
    ),
  );

  const event_showtimes_options_label = useSelector(
    selectBrandThemeText(
      BRAND_THEME_SETTING_KEYS.event_showtimes_options_label,
    ),
  );

  if (!price_box_background_color || !price_box_selected_border_color)
    return <></>;
  return (
    <>
      {product.options.map((o: ProductOption) => (
        <RadioGroup
          defaultValue={
            product.activeVariants.length !== 0
              ? product.activeVariants[0].id
              : ""
          }
          key={o.id}
        >
          <RadioGroup.Label
            className="block text-sm font-medium"
            style={{ color: product_detail_text_color }}
          >
            {product.isPass ? "Price" : event_showtimes_options_label}
          </RadioGroup.Label>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {(product.activeVariants.length !== 0
              ? product.activeVariants
              : product.inactiveVariants
            ).map((v: ProductVariant) => (
              <RadioGroup.Option
                as="div"
                style={{ backgroundColor: price_box_background_color }}
                onClick={() => setVariantId(v.id)}
                key={v.id}
                value={v.id}
                className={({ active }) =>
                  classNames(
                    "relative block border border-gray-300 rounded-lg p-2 cursor-pointer focus:outline-none",
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <RadioGroup.Label
                      as="span"
                      className="text-base font-medium text-gray-900"
                    >
                      {!product.is_giftcard && <VariantInfo variant={v} />}
                      <VariantPriceDisplay
                        variant={v}
                        onPayWhatYouCanPriceChange={onPayWhatYouCanPriceChange}
                      />
                    </RadioGroup.Label>
                    {!product.is_giftcard && (
                      <RadioGroup.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        <StockIndicator
                          in_stock_label={product.brand.in_stock_label}
                          low_inventory_message={v.low_inventory_message}
                          inventory_quantity={v.inventory_quantity}
                          original_inventory_quantity={
                            v.original_inventory_quantity
                          }
                          lowInventoryWarningThreshold={
                            v.lowInventoryWarningThreshold
                          }
                        />
                      </RadioGroup.Description>
                    )}
                    <div
                      style={{
                        borderColor: checked
                          ? price_box_selected_border_color
                          : "transparent",
                      }}
                      className={classNames(
                        active ? "border-4" : "border-4",
                        "absolute -inset-px rounded-lg pointer-events-none",
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      ))}
    </>
  );
}
