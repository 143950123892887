import { ContentBlock, ProductContentBlock } from "@models/models";
import React, { useState } from "react";
import { FaX } from "react-icons/fa6";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export default function QuickLinks({
  product_content_blocks,
}: {
  product_content_blocks: ProductContentBlock[];
}) {
  const [_product_content_blocks] = useState(
    product_content_blocks
      ? product_content_blocks.filter((p) => p.type === "quick-links")
      : [],
  );
  const [content_block, setContentBlock] = useState<ContentBlock>();
  const button_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.secondary_button_background_color,
    ),
  );
  const button_text_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.secondary_button_text_color,
    ),
  );

  const site_background_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.site_background_color),
  );
  const site_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.site_text_color),
  );

  return (
    <>
      {_product_content_blocks.length !== 0 && (
        <div className={"mt-2 flex gap-x-1.5"}>
          {_product_content_blocks.map((pcb) => (
            <div key={pcb.id}>
              {pcb.content_block.url && (
                <a
                  style={{
                    color: button_text_color,
                    backgroundColor: button_background_color,
                    border: 0,
                  }}
                  href={pcb.content_block.url}
                  className={"btn btn-xs"}
                >
                  {pcb.content_block.title}
                </a>
              )}
              {pcb.content_block.text && (
                <button
                  style={{
                    color: button_text_color,
                    backgroundColor: button_background_color,
                    border: 0,
                  }}
                  onClick={() => setContentBlock(pcb.content_block)}
                  className={"btn btn-xs"}
                >
                  {pcb.content_block.title}
                </button>
              )}
            </div>
          ))}
        </div>
      )}
      {content_block && (
        <dialog className="modal modal-open">
          <div
            className="modal-box"
            style={{
              color: site_text_color,
              backgroundColor: site_background_color,
            }}
          >
            <div className={"flex justify-end"}>
              <button onClick={() => setContentBlock(undefined)}>
                <FaX />
              </button>
            </div>
            <h3 className="font-bold text-lg">{content_block.title}</h3>
            <p className="py-4">
              <div
                dangerouslySetInnerHTML={{ __html: content_block.text }}
              ></div>
            </p>
          </div>
        </dialog>
      )}
    </>
  );
}
