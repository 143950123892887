import Image from "next/image";
import React from "react";
import { Product } from "@models/models";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export default function ProductDetailImageAndTitleMobile({
  product,
  thumbnail,
}: {
  product: Product;
  thumbnail?: string | null;
}) {
  const product_detail_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.product_detail_text_color),
  );
  if (!product_detail_text_color) return <></>;
  return (
    <div
      className={
        "w-full justify-between flex flex-row gap-4 lg:hidden items-center"
      }
    >
      <div
        style={{ color: product_detail_text_color }}
        className={"font-extrabold tracking-tight text-2xl md:text-3xl"}
      >
        {product.title}
      </div>
      <div className={"flex-none"}>
        {product.thumbnail && (
          <Image
            alt={product.title}
            src={thumbnail ? thumbnail : (product.thumbnail as string)}
            priority={true}
            width={50}
            height={50}
            className="rounded-xl w-full h-full object-center object-cover"
          />
        )}
      </div>
    </div>
  );
}
