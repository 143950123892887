import * as React from "react";
import { ExclamationIcon } from "@heroicons/react/solid";
import { FaCheck, FaExclamation } from "react-icons/fa";

type MessageProps = {
  message: string | React.ReactNode;
  type: string | undefined;
  title?: string | undefined;
};
export function Message({ message, type, title }: MessageProps) {
  return (
    <>
      {type === "success" && (
        <div className="rounded-md bg-green-100 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <FaCheck className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              {!title && (
                <h3 className="text-sm font-medium text-green-800">
                  {message}
                </h3>
              )}
              {title && message && (
                <>
                  <h3 className="text-sm font-medium text-green-800">
                    {title}
                  </h3>
                  <div className="mt-2 text-sm text-green-700">
                    <p>{message}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {type !== "success" && type !== "warning" && (
        <div className="rounded-md bg-red-100 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <FaExclamation
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              {!title && (
                <h3 className="text-sm font-medium text-red-800">{message}</h3>
              )}
              {title && message && (
                <>
                  <h3 className="text-sm font-medium text-red-800">{title}</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>{message}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {type === "warning" && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              {title && (
                <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
              )}
              <div className="mt-2 text-sm text-yellow-700">
                <p>{message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
