import { ProductVariant } from "@medusajs/medusa";
import { Product } from "../models/models";
import dayjs from "dayjs";
import { formatDate } from "@jumpcomedy/utils";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
type VariantInfoProps = {
  variant: ProductVariant;
};

export function VariantInfo({ variant }: VariantInfoProps) {
  const result = getVariantDisplayTitle2(variant);
  const price_box_showtime_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.price_box_showtime_text_color,
    ),
  );
  const price_box_title_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.price_box_title_text_color,
    ),
  );

  if (!price_box_showtime_color || !price_box_title_color) return <></>;
  return (
    <>
      {result.showtime && (
        <div
          className={"text-md  font-semibold text-jump"}
          style={{ color: price_box_showtime_color }}
        >
          {result.showtime}
        </div>
      )}
      <div style={{ color: price_box_title_color }} className={"text-sm"}>
        {result.title}
      </div>
    </>
  );
}

export const getVariantShowtimeOnly = (variant: ProductVariant) => {
  return formatDate(variant.startsAt);
};

export const getVariantsShowtimeOnly = (product: Product) => {
  return product.variants.map((v) => getVariantShowtimeOnly(v)).join(", ");
};

export const getCondensedShowtimes = (product: Product) => {
  let result = "";
  product.variants.forEach((value, index) => {
    result += formatDate(value.startsAt, { format: "ddd MMM D, h:mm A" });
    if (index !== product.variants.length - 1) {
      result += ", ";
    }
  });
  return result;
};

type ConsolidatedShowTimesOptions = {
  hidePast?: boolean;
};

export const conslidatedShowtimesDisplay = (
  product: Product,
  options?: ConsolidatedShowTimesOptions,
) => {
  const result = consolidatedShowtimes(product, options);
  const showtimes_to_display = 3;
  if (result.length <= showtimes_to_display) {
    return result.join(", ");
  } else {
    return `${result.slice(0, showtimes_to_display).join(", ")} and ${result.length - showtimes_to_display} more`;
  }
};
export const consolidatedShowtimes = (
  product: Product,
  options?: ConsolidatedShowTimesOptions,
) => {
  let showtimes = product.variants.map((v) => ({
    startsAt: v.startsAt,
    startsAtUtc: v.startsAtUtc,
  }));

  if (options && options.hidePast === true) {
    // @ts-ignore
    const threeHoursAgo = dayjs.utc().subtract(3, "h");
    // @ts-ignore
    showtimes = showtimes.filter(
      (s) =>
        (s.startsAtUtc &&
          // @ts-ignore
          dayjs.utc(s.startsAtUtc).isSameOrAfter(threeHoursAgo)) ||
        !s.startsAtUtc,
    );
  }
  const result = showtimes.map((s) => formatDate(s.startsAt)).flat();
  return [...new Set(result)];
};

export const getVariantDisplayTitle = (
  variant: ProductVariant,
  withFormatting: boolean = true,
) => {
  const showtime =
    formatDate(variant.startsAt) +
    (variant.virtual_event_timezone
      ? ` ${variant.virtual_event_timezone}`
      : "");
  const title = variant.title;
  if (!title || title.trim() === "" || title === showtime) {
    if (!variant.display_showtime) return <></>;
    return withFormatting === true ? (
      <div className={"text-md font-semibold text-jump"}>{showtime}</div>
    ) : (
      <>{showtime}</>
    );
  } else if (showtime && title) {
    return withFormatting === true ? (
      <>
        <>
          {variant.display_showtime && (
            <div className={"text-md  font-semibold text-jump"}>{showtime}</div>
          )}
        </>
        <div className={"text-sm"}>{title}</div>
      </>
    ) : (
      <>
        {variant.display_showtime && <>{showtime} / </>}
        {title}
      </>
    );
  } else {
    return <div />;
  }
};

export const getVariantDisplayTitle2 = (variant: ProductVariant) => {
  const showtime =
    formatDate(variant.startsAt) +
    (variant.virtual_event_timezone
      ? ` ${variant.virtual_event_timezone}`
      : "");
  const title = variant.title;
  if (!title || title.trim() === "" || title === showtime) {
    return { title: null, showtime: showtime };
  } else if (showtime && title) {
    return {
      title: title,
      showtime: variant.display_showtime ? showtime : null,
    };
  } else {
    return { title: null, showtime: null };
  }
};
