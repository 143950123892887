import React from "react";
import { Product } from "@models/models";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export default function ProductDetailTitleDesktop({
  product,
}: {
  product: Product;
}) {
  const product_detail_text_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.product_detail_text_color),
  );
  if (!product_detail_text_color) return <></>;
  return (
    <h1
      style={{ color: product_detail_text_color }}
      className="hidden lg:block text-3xl font-extrabold tracking-tight sm:text-4xl"
    >
      {product.title}
    </h1>
  );
}
