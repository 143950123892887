import { ProductVariant } from "@medusajs/medusa";
import { formatAmount, formatMoneyAmount } from "@utils/price";
import React, { useEffect, useState } from "react";
import { useCart } from "@hooks/cart";
import { useLazyGetPriceBasedVariantPriceRangeQuery } from "@store/adsSlice";
import getDisplayPrice from "@utils/get-display-price";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

function displayPrice(v: ProductVariant, currency_code: string) {
  const price = getDisplayPrice(v, currency_code);
  if (!price.higher_price) {
    return <div className={"text-2xl"}>{formatMoneyAmount(price.price)}</div>;
  } else if (price.higher_price && price.price.price_list) {
    return (
      <div>
        <div className={"w-fit text-sm  bg-jump text-white rounded p-1"}>
          {price.price.price_list.name}
        </div>
        <div className={"text-2xl"}>
          {formatMoneyAmount(price.price)}
          <div className={"line-through text-sm"}>
            {formatMoneyAmount(price.higher_price)}
          </div>
        </div>
      </div>
    );
  }
}

function displayPriceRange(
  variant: ProductVariant,
  range: { from_amount: number; to_amount: number },
  currency_code: string,
) {
  let display;
  if (!range.from_amount && !range.to_amount) {
    return displayPrice(variant, currency_code);
  } else if (
    range.from_amount &&
    range.to_amount &&
    range.from_amount !== range.to_amount
  ) {
    display = `${formatAmount(currency_code, range.from_amount)}-${formatAmount("", range.to_amount)}`;
  } else if (range.from_amount === range.to_amount) {
    display = `${formatAmount(currency_code, range.from_amount)}`;
  }

  return <div className={"text-2xl"}>{display}</div>;
}

export default function VariantPriceDisplay({
  variant,
  onPayWhatYouCanPriceChange,
}: {
  variant: ProductVariant;
  onPayWhatYouCanPriceChange?: (price: number) => void;
}) {
  const cart = useCart();
  const [getPriceBasedVariantPriceRange] =
    useLazyGetPriceBasedVariantPriceRangeQuery();
  const [priceRange, setPriceRange] = useState<{
    from_amount: number;
    to_amount: number;
  }>();
  useEffect(() => {
    if (!variant.seatingChart || !cart || !cart.region) return;
    getPriceBasedVariantPriceRange({
      variant_id: variant.id,
      currency_code: cart.region.currency_code,
    }).then((r) => {
      setPriceRange(r.data as any);
    });
  }, [cart?.region]);

  const price_box_price_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.price_box_price_text_color,
    ),
  );

  if (!cart || !cart.region || !price_box_price_color) return <></>;
  return (
    <>
      {!variant.is_pay_what_you_like && (
        <div style={{ color: price_box_price_color }}>
          {!variant.seatingChart &&
            displayPrice(variant, cart.region.currency_code)}
          {variant.seatingChart &&
            priceRange !== undefined &&
            displayPriceRange(variant, priceRange, cart.region.currency_code)}
        </div>
      )}
      {onPayWhatYouCanPriceChange && variant.is_pay_what_you_like && (
        <div
          className={
            "flex flex-col bg-gray-100 p-2 rounded-xl gap-2 my-2 items-center"
          }
        >
          {variant.pay_what_you_like_label && (
            <div className={"font-semibold text-lg"}>
              {variant.pay_what_you_like_label}
            </div>
          )}
          <div className={"flex gap-x-2 text-lg items-center"}>
            <div>{cart.region.currency_code.toUpperCase()}</div>
            <div>
              <input
                onChange={(e: any) => {
                  const amount = e.target.value * 100;
                  if (amount >= variant.prices[0].amount) {
                    onPayWhatYouCanPriceChange(e.target.value * 100);
                  }
                }}
                min={variant.prices[0].amount / 100}
                type={"number"}
                className={"input input-bordered w-28 h-10"}
                defaultValue={variant.prices[0].amount / 100}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
